import { Box, Flex, Text, Link as ChakraLink, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getStatusText, truncateAddress } from '@features/panels/alertsPanel/utils/alertUtils';
import { selectUser } from '@features/user-settings/userSlice';
import type { Alert } from '@models/alert';

interface AlertHeaderProps {
  alert: Alert;
  onReassign: () => void;
}

const AlertHeader: React.FC<AlertHeaderProps> = ({ alert, onReassign }) => {
  const isWatchlist = alert.resourcetype === 'WatchlistAlert';
  const currentUser = useSelector(selectUser);

  return (
    <Box p={5} bg="white" outline="1px solid black" mb={4}>
      <Flex justifyContent="space-between">
        <Flex direction="column">
          <Text fontSize="xl" fontWeight={700}>
            {`Alert (ID: ${alert.id})`}
          </Text>
          <Text>
            Creation Date: {alert?.createdAt != null ? dayjs(alert.createdAt).format('YYYY-MM-DD hh:mm:ss A') : '-'}
          </Text>
          <Text>
            Last Modification:{' '}
            {alert?.closedDate != null ? dayjs(alert.closedDate).format('YYYY-MM-DD hh:mm:ss A') : '-'}
          </Text>
        </Flex>
        <Flex direction="column">
          <Text> Alert Type: {alert.resourcetype}</Text>
          <Text> Alert Status: {getStatusText(alert.status)}</Text>
          <Text>
            {isWatchlist ? 'Profile ID: ' : 'Wallet Address: '}
            <ChakraLink
              as={Link}
              to={`/dashboard/${isWatchlist ? 'profiles' : 'wallets'}/${isWatchlist ? alert.profile.id : alert.wallet.id}`}
              color="blue.400"
              textDecoration="underline"
            >
              {isWatchlist ? alert.profile.id : truncateAddress(alert.wallet.address)}{' '}
            </ChakraLink>
          </Text>
          {alert.reviewedBy != null && (
            <Flex alignItems="center">
              <Text mr={2}>
                Assigned to: {alert.reviewedBy.firstName} {alert.reviewedBy.lastName} ({alert.reviewedBy.email})
              </Text>
              {currentUser.email !== alert.reviewedBy.email && (
                <Button size="sm" onClick={onReassign} bg="black" color="white" _hover={{ bg: 'gray.800' }}>
                  Reassign to me
                </Button>
              )}
            </Flex>
          )}
        </Flex>
        {isWatchlist ? (
          <Flex direction="column">
            <Text> Total Hits: {alert.profileInquiries?.length}</Text>
            <Text>
              Total False Positives:{' '}
              {alert.profileInquiries?.filter((inquiry) => inquiry.resolution === 'FALSE_POSITIVE').length}
            </Text>
            <Text>
              Total True Matches:{' '}
              {alert.profileInquiries?.filter((inquiry) => inquiry.resolution === 'TRUE_MATCH').length}
            </Text>
          </Flex>
        ) : (
          <Flex direction="column">
            <Text> Previous Wallet Exposure: {alert.prevInquiryResult?.result?.risk}</Text>
            <Text> New Wallet Exposure: {alert.currentInquiryResult?.result?.risk}</Text>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default AlertHeader;
