import { Box, Button, HStack } from '@chakra-ui/react';
import { useState } from 'react';

import { Loading } from '@features/shared/components';
import { PROFILE_TYPE, type IProfile } from '@models/profileTypes';
import {
  useCreateProfileMutation,
  useUpdateProfileMutation,
  useGetRelatedProfileQuery,
  useCreateRelatedProfileMutation,
  useUpdateRelatedProfileMutation
} from '@services/canaria.services';

import RelatedProfileList from './RelatedProfileList';
import { AddPrimaryPartyForm } from '../individual/AddPrimaryParty';

interface RelatedProfilesFormProps {
  orgId: string;
  profile: IProfile;
  showForm: boolean;
  setShowForm: (value: boolean) => void;
  children?: React.ReactNode;
}

const RelatedProfileManager: React.FC<RelatedProfilesFormProps> = ({
  orgId,
  profile,
  showForm,
  setShowForm,
  children
}) => {
  const [createProfile] = useCreateProfileMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [createRelatedProfile] = useCreateRelatedProfileMutation();
  const [updateRelatedProfile] = useUpdateRelatedProfileMutation();

  const { data: dataRelatedProfiles, isLoading: isLoadingRelatedProfiles } = useGetRelatedProfileQuery({
    orgId,
    profileId: profile.id
  });

  const [currentRelatedProfile, setCurrentRelatedProfile] = useState<{
    id: string;
    relationTypes: Array<{
      value: string;
      name: string;
    }>;
    profile: IProfile;
  } | null>(null);

  if (isLoadingRelatedProfiles) {
    return <Loading />;
  }

  const relatedProfiles = dataRelatedProfiles?.results;

  const handleAddRelatedProfile = async (relatedProfile): Promise<void> => {
    let relatedProfileId = relatedProfile.id;
    if (currentRelatedProfile == null) {
      // We create a new profile first with basic information
      const relatedProfileCreated = await createProfile({
        orgId,
        name: relatedProfile.fullLegalName,
        profileGroup: profile.profileGroup.id,
        resourcetype: relatedProfile.profileType,
        // Since it's a related party, we set isPrimary:false
        isPrimary: false
      }).unwrap();

      relatedProfileId = relatedProfileCreated.id;
      await createRelatedProfile({
        orgId,
        profileId: profile.id,
        toProfile: relatedProfileId,
        relationTypes: relatedProfile.relationTypes
      }).unwrap();
    } else {
      await updateRelatedProfile({
        orgId,
        profileId: profile.id,
        relatedProfileId: currentRelatedProfile.id,
        relationTypes: relatedProfile.relationTypes
      }).unwrap();
    }
    if (relatedProfile.profileType === PROFILE_TYPE.INDIVIDUAL) {
      await updateProfile({
        orgId,
        profileId: relatedProfileId,
        address: relatedProfile.address,
        citizenship: relatedProfile.citizenship,
        city: relatedProfile.city,
        postalCode: relatedProfile.postalCode,
        countryOfResidence: relatedProfile.countryOfResidence,
        dateOfBirth: relatedProfile.dateOfBirth,
        email: relatedProfile.email,
        fullLegalName: relatedProfile.fullLegalName,
        gender: relatedProfile.gender,
        idIssuer: relatedProfile.idIssuer,
        idNumber: relatedProfile.idNumber,
        idType: relatedProfile.idType,
        natureOfEmployment: relatedProfile.natureOfEmployment,
        placeOfBirth: relatedProfile.placeOfBirth,
        state: relatedProfile.state
      }).unwrap();
    } else {
      await updateProfile({
        orgId,
        profileId: relatedProfileId,
        fullLegalName: relatedProfile.fullLegalName,
        dbaTradeName: relatedProfile.dbaTradeName,
        entityFormationType: relatedProfile.entityFormationType,
        placeOfEstablishment: relatedProfile.placeOfEstablishment,
        dateOfEstablishment: relatedProfile.dateOfEstablishment,
        uniqueIdentificationNumber: relatedProfile.uniqueIdentificationNumber,
        registeredBusinessAddress: relatedProfile.registeredBusinessAddress,
        mailingAddress: relatedProfile.mailingAddress,
        physicalAddress: relatedProfile.physicalAddress,
        companyWebsite: relatedProfile.companyWebsite
      });
    }

    setCurrentRelatedProfile(null);
    setShowForm(false);
  };

  if (showForm) {
    return (
      <>
        <AddPrimaryPartyForm
          profile={currentRelatedProfile?.profile}
          onSubmit={handleAddRelatedProfile}
          isRelatedParty={true}
          relationTypes={currentRelatedProfile?.relationTypes?.map((relationType) => relationType.value)}
        />
        <Box width="fit-content" marginX="auto" mt={4}>
          <HStack spacing={4}>
            <Button
              colorScheme="gray"
              variant="outline"
              onClick={() => {
                setShowForm(false);
                setCurrentRelatedProfile(null);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" bg="black" color="white" form="IndividualPrimaryParty">
              {currentRelatedProfile != null ? 'Update' : 'Add Related Party'}
            </Button>
          </HStack>
        </Box>
      </>
    );
  }

  return (
    <>
      {relatedProfiles?.length === 0 ? (
        children
      ) : (
        <RelatedProfileList
          isLoadingRelatedProfiles={isLoadingRelatedProfiles}
          relatedProfiles={relatedProfiles}
          onClickEdit={(relatedProfile) => {
            setShowForm(true);
            setCurrentRelatedProfile(relatedProfile);
          }}
          onClickAdd={() => {
            setShowForm(true);
          }}
        />
      )}
    </>
  );
};

export default RelatedProfileManager;
