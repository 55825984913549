import { formatDate } from '@features/panels/alertsPanel/utils/alertUtils';
import type { ScreeningHit } from '@models/alert';

interface MappedScreeningHit {
  entityName: string;
  sourceList: string;
  category: string;
  score: string;
  entityType: string | undefined;
  additionalFields: {
    firstName: string | undefined;
    middleName: string | undefined;
    lastName: string | undefined;
    aliases: string[];
    citizenships: string[];
    birthDates: string[];
    addresses: string[];
    ids: string[];
    country: string[];
    gender: string[];
    registrationNumber: string[];
  };
}

export const mapScreeningHit = (hit: ScreeningHit): MappedScreeningHit => {
  const result = hit.result ?? {};
  if (hit.recordType === 'Castellum') {
    return {
      entityName: hit.resultName ?? 'N/A',
      sourceList: result.listEntry?.listSource?.name ?? 'N/A',
      category: result.listEntry?.listSource?.category?.name ?? 'N/A',
      score: result.score != null ? `${(result.score * 100).toFixed(2)}%` : 'N/A',
      entityType: result?.listEntry?.type?.name,
      additionalFields: {
        firstName: result?.listEntry?.name?.firstName,
        middleName: result?.listEntry?.name?.middleName,
        lastName: result?.listEntry?.name?.lastName,
        aliases:
          result?.listEntry?.aliases
            ?.map((alias) => {
              if (alias.fullName === undefined) {
                if (alias.firstName !== undefined && alias.lastName !== undefined) {
                  return `${alias.firstName} ${alias.lastName}`;
                }
              }
              return alias.fullName;
            })
            .filter(Boolean) ?? [],
        citizenships:
          result?.listEntry?.citizenships
            ?.map((citizenship) => {
              if (citizenship.official_name === undefined) {
                return citizenship.name;
              }
              return citizenship.official_name;
            })
            .filter(Boolean) ?? [],
        birthDates:
          result?.listEntry?.dobs
            ?.map((dob) => {
              if (dob.raw !== undefined) return dob.raw;
              if (dob.start !== undefined && dob.end !== undefined) {
                const startFormatted = formatDate(dob.start);
                const endFormatted = formatDate(dob.end);
                return startFormatted === endFormatted ? startFormatted : `${startFormatted} - ${endFormatted}`;
              }
              return undefined;
            })
            .filter(Boolean) ?? [],
        addresses: result?.listEntry?.addresses?.map((address) => address.address).filter(Boolean) ?? [],
        ids: result?.listEntry?.name?.ids?.map((id) => `${id.type}: ${id.number}`).filter(Boolean) ?? [],
        country: [],
        gender: [],
        registrationNumber: []
      }
    };
  } else if (hit.recordType === 'OpenSanctions') {
    return {
      entityName: hit.resultName ?? 'N/A',
      sourceList: result.datasets?.join('; ') ?? 'N/A',
      category: result.properties.topics?.[0] ?? 'N/A',
      score: result.score != null ? `${(result.score * 100).toFixed(2)}%` : 'N/A',
      entityType: result.schema,
      additionalFields: {
        firstName: result.properties.firstName?.join(' '),
        middleName: result.properties.middleName?.join(' ') ?? '',
        lastName:
          result.properties.lastName?.join(' ') ??
          [result.properties.fatherName?.[0], result.properties.motherName?.[0]].filter(Boolean).join(' '),
        aliases: (result.properties.alias ?? [])
          .concat(result.properties.weakAlias ?? [])
          .concat(result.properties.name ?? []),
        country: result.properties.country ?? [],
        citizenships: result.properties.nationality ?? [],
        birthDates: result.properties.birthDate ?? [],
        addresses: result.properties.address ?? [],
        ids: result.properties.idNumber ?? [],
        gender: result.properties.gender ?? [],
        registrationNumber: result.properties.registrationNumber ?? []
      }
    };
  }
  throw new Error('Unsupported record type');
};
