import { EditIcon } from '@chakra-ui/icons';
import {
  Box,
  IconButton,
  TagCloseButton,
  TagLabel,
  Tag,
  WrapItem,
  Wrap,
  Flex,
  Stack,
  useToast
} from '@chakra-ui/react';
import {
  Button as MaterialButton,
  ThemeProvider,
  createTheme,
  Select as MaterialSelect,
  MenuItem
} from '@mui/material';
import { MaterialReactTable, type MRT_ColumnDef } from 'material-react-table';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';

import { selectActiveOrgID } from '@features/user-settings/userSlice';
import { PROFILE_TYPE } from '@models/profileTypes';
import { useUpdateRelatedProfileMutation } from '@services/canaria.services';
import { RELATION_TYPE_OPTIONS } from '@utils/consts';

interface RelatedProfileListProps {
  isLoadingRelatedProfiles: boolean;
  relatedProfiles: any[];
  onClickEdit: (party: any) => void;
  onClickAdd: () => void;
}

const RelatedProfileList: React.FC<RelatedProfileListProps> = ({
  relatedProfiles,
  isLoadingRelatedProfiles,
  onClickAdd
}) => {
  const defaultMaterialTheme = createTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const getProfileId = (): string | null => {
    const pathProfileId = params.profileId ?? params.id;
    if (pathProfileId == null) {
      const searchParams = new URLSearchParams(location.search);
      return searchParams.get('profileId');
    }
    return pathProfileId;
  };

  const columns = useMemo<Array<MRT_ColumnDef<any>>>(
    () => [
      {
        id: 'id',
        header: 'ID',
        accessorKey: 'profile.id',
        size: 40,
        grow: 0
      },
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'profile.fullLegalName'
      },
      {
        id: 'resourcetype',
        header: 'Type',
        accessorKey: 'profile.resourcetype',
        Cell: ({ row }) => {
          return row.original.profile.resourcetype === PROFILE_TYPE.INDIVIDUAL ? 'Individual' : 'Entity';
        }
      },
      {
        id: 'relationship',
        header: 'Relationship to primary Party',
        accessorKey: 'relationType',
        Cell: ({ row }) => {
          const [isEditing, setIsEditing] = useState(false);
          const [relationships, setRelationships] = useState(row.original.relationTypes);
          const orgId = useSelector(selectActiveOrgID);
          const profileId = getProfileId();
          const [updateRelatedProfile] = useUpdateRelatedProfileMutation();
          const toast = useToast();

          const handleSaveRelationships = async (): Promise<void> => {
            try {
              if (profileId == null || orgId == null) {
                throw new Error('Profile ID or Organization ID is null');
              }
              await updateRelatedProfile({
                profileId,
                orgId,
                relatedProfileId: row.original.id,
                relationTypes: relationships.map((r) => r.value)
              });
              setIsEditing(false);
            } catch (error) {
              toast({
                title: 'Failed to update relationships',
                status: 'error'
              });
            }
          };

          if (!Array.isArray(relationships)) return null;

          if (!isEditing) {
            return (
              <Flex alignItems="center" gap={4}>
                <Stack direction="column" spacing={2}>
                  {relationships.map((rel) => (
                    <Box key={rel.name}>{rel.name}</Box>
                  ))}
                </Stack>
                <IconButton
                  icon={<EditIcon />}
                  aria-label="Edit relationship"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
              </Flex>
            );
          }
          return (
            <Box p={4} borderWidth="1px" borderRadius="lg" bg="white" shadow="sm">
              <Wrap spacing={2} mb={4}>
                {relationships.map((rel) => (
                  <WrapItem key={rel.value}>
                    <Tag size="md" borderRadius="full" variant="solid" colorScheme="blue">
                      <TagLabel>{RELATION_TYPE_OPTIONS[rel.value]}</TagLabel>
                      <TagCloseButton
                        onClick={() => {
                          setRelationships(relationships.filter((r) => r !== rel));
                        }}
                      />
                    </Tag>
                  </WrapItem>
                ))}
              </Wrap>
              <Flex gap={3}>
                <MaterialSelect
                  fullWidth
                  value=""
                  onChange={(e) => {
                    if (e.target.value !== '') {
                      setRelationships([
                        ...relationships,
                        { name: RELATION_TYPE_OPTIONS[e.target.value], value: e.target.value }
                      ]);
                    }
                  }}
                >
                  {Object.entries(RELATION_TYPE_OPTIONS).map(
                    ([value, label]) =>
                      !relationships.some((r) => r.value === value) && (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      )
                  )}
                </MaterialSelect>
                <MaterialButton variant="contained" color="primary" size="small" onClick={handleSaveRelationships}>
                  Save
                </MaterialButton>
                <MaterialButton
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    setIsEditing(false);
                  }}
                >
                  Cancel
                </MaterialButton>
              </Flex>
            </Box>
          );
        }
      },
      {
        id: 'total_unresolved_hits',
        header: 'Actions',
        accessorKey: 'profile.totalUnresolvedHits',
        Cell: ({ row }) => {
          const totalUnresolvedHits = row.original.profile.totalUnresolvedHits;
          const relatedProfileId = row.original.profile.id;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {totalUnresolvedHits > 0 && (
                <span style={{ color: 'red', marginRight: '8px' }}>{totalUnresolvedHits} unresolved hits</span>
              )}
              <MaterialButton
                variant="contained"
                size="small"
                sx={{ backgroundColor: 'black', color: 'white', textTransform: 'none' }}
                onClick={() => {
                  navigate(`/dashboard/profiles/${relatedProfileId}/screenings`);
                }}
              >
                Screening Hits
              </MaterialButton>
            </div>
          );
        }
      },
      {
        id: 'view',
        header: '',
        accessorKey: 'view',
        Cell: ({ row }) => (
          <MaterialButton
            component={Link}
            to={`/dashboard/profiles/${row.original.profile.id}`}
            variant="contained"
            color="primary"
            size="small"
          >
            View
          </MaterialButton>
        )
      }
    ],
    [navigate]
  );
  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialReactTable
        columns={columns}
        data={relatedProfiles ?? []}
        renderTopToolbarCustomActions={() => {
          return (
            <MaterialButton
              variant="contained"
              onClick={() => {
                onClickAdd();
              }}
            >
              Register new related party
            </MaterialButton>
          );
        }}
        state={{
          isLoading: isLoadingRelatedProfiles
        }}
        initialState={{ density: 'compact' }}
      />
    </ThemeProvider>
  );
};

export default RelatedProfileList;
