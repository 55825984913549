import { Box, Flex, Grid, GridItem, Select, Text } from '@chakra-ui/react';
import { useState, useMemo } from 'react';

import MatchingScreeningHit from '@features/panels/alertsPanel/components/MatchingScreeningHit.component';

const AlertDetailScreeningHits = ({
  alert,
  onResolveMatch,
  activeEntityId,
  setActiveEntityId
}: {
  alert: any;
  onResolveMatch: any;
  activeEntityId: any;
  setActiveEntityId: any;
}): JSX.Element => {
  const [filter, setFilter] = useState<'all' | 'unresolved' | 'resolved'>('all');

  const filteredEntities = useMemo(() => {
    return (alert.profileInquiries ?? []).filter((inquiry) => {
      if (filter === 'all') return true;
      if (filter === 'unresolved') return inquiry.resolution === 'NO_RESOLUTION';
      if (filter === 'resolved') return inquiry.resolution !== 'NO_RESOLUTION';
      return true;
    });
  }, [alert.profileInquiries, filter]);

  const ProfileDetail = ({ label, value }): JSX.Element | null => {
    if (value == null) return null;

    return (
      <GridItem>
        <Flex direction="row" gap={2}>
          <Text>{label}:</Text>
          <Text fontWeight={600}>{value}</Text>
        </Flex>
      </GridItem>
    );
  };

  const AlertProfileDetails = ({ profile }): JSX.Element => {
    return (
      <>
        <ProfileDetail label="Entity Type" value={profile?.entityType} />
        <ProfileDetail label="Full Name" value={profile?.fullLegalName} />
        <ProfileDetail label="Date of Birth" value={profile?.dateOfBirth} />
        <ProfileDetail label="DBA Name" value={profile?.dbaTradeName} />
        <ProfileDetail label="Citizenship" value={profile?.citizenship?.englishName} />
        <ProfileDetail label="Country of Establishment" value={profile?.placeOfEstablishment?.englishName} />
        <ProfileDetail label="Place of Birth" value={profile?.placeOfBirth?.englishName} />
        <ProfileDetail label="ID Type" value={profile?.idType?.name} />
        <ProfileDetail label="ID Number" value={profile?.idNumber} />
        <ProfileDetail label="ID Issuer" value={profile?.idIssuer?.englishName} />
        <ProfileDetail label="Unique Identification Number" value={profile?.uniqueIdentificationNumber} />
      </>
    );
  };

  return (
    <Box p={5} bg="white" outline="1px solid black" mb={4}>
      <Text fontWeight={800}>Profile Information</Text>
      <Grid
        templateColumns="auto auto"
        pt={6}
        columnGap={5}
        flex="col"
        outline="1px solid black"
        bg="rgba(190, 217, 3, 0.2)"
        padding={5}
      >
        <AlertProfileDetails profile={alert.profile} />
      </Grid>
      <Flex justifyContent="space-between" alignItems="center" mt={5} mb={2}>
        <Text fontWeight={800}>Matching Entities</Text>
        <Select
          width="200px"
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value as any);
          }}
        >
          <option value="all">All Hits</option>
          <option value="unresolved">Unresolved Hits</option>
          <option value="resolved">Resolved Hits</option>
        </Select>
      </Flex>
      <Box height="400px" overflowY="auto">
        {filteredEntities.length > 0 ? (
          <>
            <Flex justifyContent="space-around" alignItems="center" gap={4} mb={4}>
              <Text fontWeight={600}>Entity Name</Text>
              <Text fontWeight={600}>Source List</Text>
              <Text fontWeight={600}>Category</Text>
              <Text fontWeight={600}>Score</Text>
              <Text fontWeight={600}>Actions</Text>
            </Flex>
            {filteredEntities.map((inquiry) => (
              <MatchingScreeningHit
                key={inquiry.id}
                screeningHit={inquiry}
                onResolve={onResolveMatch}
                isExpanded={activeEntityId === inquiry.id}
                onToggle={() => {
                  setActiveEntityId(activeEntityId === inquiry.id ? null : inquiry.id);
                }}
              />
            ))}
          </>
        ) : (
          <Flex height="100%" alignItems="center" justifyContent="center">
            <Box textAlign="center" color="gray.500">
              <Text>
                No matches found
                {filter !== 'all' ? ' for the current filter' : ''}.
              </Text>
              {filter !== 'all' && (
                <Text mt={2}>
                  Try changing the filter to see {filter === 'unresolved' ? 'resolved' : 'unresolved'} matches.
                </Text>
              )}
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default AlertDetailScreeningHits;
