import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { BACKEND_URL } from '../env';
import { convertObjectKeysToCamelCase } from './utils';
import { type ProfileFormData } from '../pages/RFIForm/ProfileSection';

export enum RFIStatus {
  PENDING = 'pending',
  RESPONDED = 'responded'
}

interface RFI {
  requested_documents: Record<string, string>;
  requested_fields: string[];
  status: RFIStatus;
  organization_image: string;
}

interface RFIResponse {
  rfiId: string;
  attachments: string[];
  fieldsResponses: {
    ControlPerson?: ProfileFormData;
    UBOs?: Array<ProfileFormData | null>;
    [key: string]: string | ProfileFormData | Array<ProfileFormData | null> | undefined;
  };
  additionalInformation: string;
}

export interface RelatedProfile extends Record<string, string | File> {}

export const canariaRFIApi = createApi({
  reducerPath: 'canariaRFIApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${BACKEND_URL}/api/` }),
  tagTypes: ['RFI'],
  endpoints: (builder) => ({
    getRFIById: builder.query<RFI, string>({
      query: (rfiId: string) => `rfis/${rfiId}/`,
      providesTags: (result, error, rfiId) => [{ type: 'RFI', id: rfiId }]
    }),
    submitRFIResponse: builder.mutation<{ message: string }, RFIResponse>({
      query: ({ rfiId, attachments, fieldsResponses, additionalInformation }) => ({
        url: `rfis/${rfiId}/submit/`,
        method: 'POST',
        body: {
          attachments,
          fields_responses: fieldsResponses,
          additional_information: additionalInformation
        }
      }),
      invalidatesTags: (result, error, { rfiId }) => [{ type: 'RFI', id: rfiId }]
    }),
    getSignedUrl: builder.mutation<
      { signed_url: string; temp_file_path: string },
      { filename: string; mimeType: string; fileSize: number; checksum: string }
    >({
      query: ({ filename, mimeType, fileSize, checksum }) => ({
        url: 'public-upload/get-signed-url/',
        method: 'POST',
        body: { filename, mime_type: mimeType, file_size: fileSize, checksum }
      })
    }),
    getCountries: builder.query({
      query: () => 'utils/countries/',
      transformResponse: (response: Record<string, string>) => convertObjectKeysToCamelCase(response)
    })
  })
});

export const { useGetRFIByIdQuery, useSubmitRFIResponseMutation, useGetSignedUrlMutation, useGetCountriesQuery } =
  canariaRFIApi;
