import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { Flex, IconButton, Input, FormControl, FormLabel, Text, Spacer } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import DatePicker from 'react-datepicker';

interface EditableDateProps {
  label: string;
  value: Date | string | null;
  onConfirmChange: (value: string) => void;
  isDisabled?: boolean;
}

const EditableDate: React.FC<EditableDateProps> = ({ label, value, onConfirmChange, isDisabled = false }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState<Date | null>(
    value === null ? null : value instanceof Date ? value : parseISO(value)
  );

  const handleEdit = (): void => {
    setIsEditing(true);
  };

  const handleCancel = (): void => {
    setIsEditing(false);
    if (value === null) {
      return;
    }
    setEditValue(value instanceof Date ? value : parseISO(value));
  };

  const handleConfirm = (): void => {
    const confirmedValue = value === null ? null : value instanceof Date ? value : new Date(value);

    if (editValue instanceof Date) {
      if (confirmedValue === null || editValue.getTime() !== confirmedValue.getTime()) {
        const formattedDate = editValue.toISOString().split('T')[0];
        onConfirmChange(formattedDate);
      }
    }
    setIsEditing(false);
  };

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel mb="0" width="175px" fontSize="sm">
        {label}
      </FormLabel>
      <Flex flex={1} align="center">
        {isEditing ? (
          <>
            <DatePicker
              selected={editValue}
              onChange={(date) => {
                setEditValue(date);
              }}
              autoComplete="off"
              customInput={<Input size="sm" />}
              dateFormat="yyyy-MM-dd"
            />
            <Spacer />
            <IconButton ml="1" size="sm" icon={<CheckIcon />} onClick={handleConfirm} aria-label="confirm" />
            <IconButton ml="1" size="sm" icon={<CloseIcon />} onClick={handleCancel} aria-label="cancel" />
          </>
        ) : (
          <>
            <Text fontSize="sm">{value != null && format(parseISO(value as string), 'yyyy-MM-dd')}</Text>
            <Spacer />
            {!isDisabled && <IconButton size="sm" icon={<EditIcon />} onClick={handleEdit} aria-label="edit" />}
          </>
        )}
      </Flex>
    </FormControl>
  );
};

export default EditableDate;
