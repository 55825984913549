import { type UseToastOptions } from '@chakra-ui/react';

const TOAST_DEFAULT: UseToastOptions = {
  duration: 5000,
  isClosable: true
};
export const TOAST_DEFAULT_ERROR: UseToastOptions = {
  status: 'error',
  ...TOAST_DEFAULT
};

export const TOAST_DEFAULT_SUCCESS: UseToastOptions = {
  status: 'success',
  ...TOAST_DEFAULT
};

interface CreateToast {
  title: string;
  description: string;
}

export const createToastSuccess = (options: CreateToast): UseToastOptions => {
  return {
    title: options.title,
    description: options.description,
    ...TOAST_DEFAULT_SUCCESS
  };
};

export const createToastError = (options: CreateToast): UseToastOptions => {
  return {
    title: options.title,
    description: options.description,
    ...TOAST_DEFAULT_ERROR
  };
};
